import React from 'react';
import { Box, BoxProps, Typography, keyframes } from '@mui/material';


interface Props {
    imgProps?: BoxProps<'img'>;
};

const glow = (color?: string) => keyframes`
    0% {
        box-shadow: 0 0 10px 5px ${color};
    }
    100% {
        box-shadow: 0 0 20px 48px rgba(0, 0, 0, 0);
    }
`;


export default function RootLoader({ imgProps }: Props) {
    return (
        <Box
            sx={{
                height: "100vh",
                width: "100vw",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                bgcolor: theme => theme.palette.background.default,
                flexDirection: "column",
            }}>
            <Box
                component="img"
                id="logo"
                src="/static/logo.png"
                mt='auto'
                sx={{
                    borderRadius: '32px', overflow: 'hidden', width: "128px", height: "128px", objectFit: 'contain',
                    animation: theme => `${glow(theme.palette.divider)} 1.5s ease-in-out infinite alternate`
                }}
            />
            <Typography
                mt='auto'
                py={2}
                variant='caption'
                children='® Beyond Digital 2024, All rights Reserved'
            />
        </Box>
    );
};
