import React, { useContext, useRef } from 'react';
import { Avatar, Box, BoxProps, Container, IconButton, Stack, TextField, Typography, keyframes } from '@mui/material';
import { nanoid } from 'nanoid';
import { Brightness4, Brightness7, Send } from '@mui/icons-material';
import { ConnectionHandler, useFragment, useLazyLoadQuery, useMutation, usePaginationFragment } from 'react-relay';

import { ThemeContext } from '../hooks/useColorSchema';

import { JMMutation } from './__generated__/JMMutation.graphql';
import { JMChat_message$key } from './__generated__/JMChat_message.graphql';
import { JMChatQuery } from './__generated__/JMChatQuery.graphql';
import { JMChat_messages$key } from './__generated__/JMChat_messages.graphql';

const graphql = require('babel-plugin-relay/macro')

const mutation = graphql`mutation JMMutation($botId: String, $text: String!, $thread: ID) {
    message(input: {create: {text: $text, botId: $botId, thread: $thread}}) {success error threadId currentMessage{ id ...JMChat_message} response {id id ...JMChat_message}}
}`;

const chatQuery = graphql`query JMChatQuery($id: ID!, $hasThreadID: Boolean!) { thread(id: $id) @include(if: $hasThreadID) {id ...JMChat_messages}}`;

const loading = keyframes`
    0% {
        opacity: 0.2;
        scale: 0.8;
    }
    20% {
        opacity: 1
        scale: 0.8;
    }
    100% {
        opacity: 0.2
        scale: 0.8;
    }
`;

export const LoadingDots = (props: BoxProps) => {
    return (
        <Box
            {...props}
            component='span'
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                "& .dot": {
                    width: '10px',
                    height: '10px',
                    borderRadius: '50%',
                    backgroundColor: theme => theme.palette.primary.main,
                    margin: theme => theme.spacing(0, 0.33),
                    animation: `${loading} 1.5s ease infinite`
                },
                "& .dot:nth-child(1)": {
                    animationDelay: '0s'
                },
                "& .dot:nth-child(2)": {
                    animationDelay: '0.5s'
                },
                "& .dot:nth-child(3)": {
                    animationDelay: '1s'
                },
            }}
        >
            <span className="dot" />
            <span className="dot" />
            <span className="dot" />
        </Box>
    )
}

interface JMProps {

}

interface JMState {
    message: string;
    threadId: string | undefined;
    botId: string;
}

class JM extends React.Component<JMProps, JMState> {

    constructor(props: JMProps) {
        super(props);
        this.state = {
            message: '',
            threadId: undefined,
            botId: 'QXNzaXN0YW50VHlwZToz'
        };
    }

    MessageBubble = ({ message }: { message: JMChat_message$key }) => {
        const node = useFragment(
            graphql`
                fragment JMChat_message on MessageType {
                    id
                    content
                    role
                    createdAt
                }
            `,
            message
        );
        const c: Array<any> = node.content ? JSON.parse(node.content) : [{
            type: 'text', text: { value: ' Empty Message 🤔' }
        }];

        if (!node) return null;

        return (
            <Stack
                key={node.id}
                direction='column'
                sx={{
                    padding: theme => theme.spacing(1),
                    transition: "all 500ms ease-in-out",
                    width: '100%',
                    flexDirection: node?.role === 'ASSISTANT' ? 'flex-start' : 'flex-end',
                    display: 'flex',
                    px: 2,

                    '& .MuiTypography-root': {
                        alignSelf: node?.role === 'ASSISTANT' ? 'flex-start' : 'flex-end',
                    }
                }}
            >
                <Typography
                    variant='caption'
                    children={node?.role}
                    color='text.secondary'
                    fontWeight='500'
                />

                <Typography
                    variant='body1'
                    maxWidth={{ xs: '100%', sm: '80%', md: '60%' }}
                >
                    {c.map((x) => {
                        let e: any = new Object(x);
                        return (
                            <Box component='span' sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                {e.type === 'text' && e?.['text']?.['value']}
                                {e.type === 'image' && <Box component='img' src={e[e.type]} sx={{ maxWidth: '200px', maxHeight: '200px' }} />}
                            </Box>
                        )
                    })}
                </Typography>

                <Typography
                    variant='caption'
                    children={new Date(node?.createdAt).toLocaleString().split(', ')[1]}
                    color='text.secondary'
                    fontWeight='500'
                />

            </Stack>
        )
    }

    Conversation = ({ frag }: { frag: JMChat_messages$key }) => {
        const { data } = usePaginationFragment(
            graphql`
                fragment JMChat_messages on ThreadType 
                @refetchable(queryName: "ChatMessagesPaginationQuery")
                @argumentDefinitions( cursor: { type: "String" } count: { type: "Int", defaultValue: 10 } ){
                    messages(first: $count, after: $cursor) 
                    @connection(key: "JMChat_messages") {
                        edges {
                            cursor
                            node {
                                id
                                createdAt
                                ...JMChat_message
                            }
                        }
                    }
                }
            `,
            frag
        );

        return (
            <Stack
                direction='column-reverse'
                gap={1}
                width='100%'
                display='flex'
                component='span'
                children={data?.messages?.edges?.map((x) => <this.MessageBubble message={x?.node as JMChat_message$key} />)}
            />
        )
    }

    Chat = () => {
        const { message, threadId, botId } = this.state;
        const tid = localStorage.getItem('threadId');
        const cref = useRef<BoxProps>();
        const data = useLazyLoadQuery<JMChatQuery>(chatQuery, {
            hasThreadID: Boolean(threadId !== undefined || threadId === ''),
            id: tid ?? ''
        }, { 'fetchPolicy': 'store-and-network' });
        const [sendMessage, sendingMessage] = useMutation<JMMutation>(mutation);

        React.useEffect(() => {
            if (tid && threadId !== tid) {
                this.setState(prev => ({ ...prev, threadId: tid }))
            }
        }, [tid]);

        const fire = () => {
            if (!message) return alert('Please type a message first');
            if (threadId) {
                return sendMessage({
                    variables: { thread: threadId, 'text': message },
                    onCompleted: (res, errors) => {
                        if (res.message?.success) { return this.setState(prev => ({ ...prev, message: '' })) };
                        if (errors) { console.log(errors); }
                    },
                    updater: (store, data) => {
                        // Get the new message from the response
                        const rootField = store.getRootField('message');
                        const newMessage = rootField.getLinkedRecords('response');
                        const currentMessage = rootField.getLinkedRecord('currentMessage');
                        const threadProxy = store.get(threadId);  // replace with the correct thread ID
                        const conn = threadProxy && ConnectionHandler.getConnection(threadProxy, 'JMChat_messages');  // replace with the correct key for the connection
                        if (conn) {
                            const currentMessageNode = ConnectionHandler.createEdge(store, conn, currentMessage, 'MessageTypeEdge');
                            ConnectionHandler.insertEdgeBefore(conn, currentMessageNode);
                            newMessage.map(e => {
                                // @ts-ignore
                                const x = ConnectionHandler.createEdge(store, conn, e, 'MessageTypeEdge')
                                ConnectionHandler.insertEdgeBefore(conn, x);
                            });
                            // scroll cref to bottom
                            // @ts-ignore
                            cref.current?.scrollIntoView({ behavior: 'smooth' });
                        }
                    },
                    onError: (err) => { console.log(err); if (err?.message) { console.log(err.message); } }
                });
            }
            else if (!threadId && botId) {
                return sendMessage({
                    variables: { botId, 'text': message },
                    onCompleted: (res) => {
                        console.log(res)
                        if (res.message?.success && res?.message?.threadId) {
                            this.setState(prev => ({ ...prev, message: '' }));
                            localStorage.setItem('threadId', res?.message?.threadId);
                            window.location.reload();
                        }
                    },
                    onError: (err) => { console.log(err); if (err?.message) { alert(err.message); } }
                })
            }
        };

        React.useEffect(() => {
            const handleKeyDown = (e: KeyboardEvent) => {
                if (e.key === 'Enter' && !e.shiftKey) {
                    e.preventDefault();
                    fire();
                }
            }
            window.addEventListener('keydown', handleKeyDown);
            return () => window.removeEventListener('keydown', handleKeyDown);
        },
            [fire]
        );

        return (
            <Box
                sx={{
                    height: 'calc(100vh - 100px)',
                    minHeight: '150px',
                    width: { xs: '95vw', md: '75vw', lg: '60vw' },
                    overflow: 'hidden',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    alignItems: 'flex-start',
                    flexWrap: 'wrap',
                    p: 2,
                    display: 'flex',
                    position: 'relative',
                }}
            >
                <Stack direction='row' gap={2} justifyContent='flex-start' alignItems='center' sx={{ width: '100%', height: '50px' }} >
                    <Avatar src='/static/dp.jpeg' sx={{ width: '40px', height: '40px' }} />
                    <Stack direction='column' justifyContent='center'  >
                        <Typography variant='body1' color='primary' sx={{ fontWeight: '700' }} >J.AI</Typography>
                        <Typography variant='caption' color='text.secondary' >Jae's, personal assistant.</Typography>
                    </Stack>
                </Stack>

                <Box
                    ref={cref}
                    sx={{
                        width: '100%', height: 'calc(100% - 120px)', display: 'block', overflowY: 'scroll', borderRadius: '15px',
                        boxShadow: theme => `0px 0px 15px 0px ${theme.palette.divider}`,
                        bgcolor: 'background.paper',
                        transition: 'all 0.5s ease-in-out',
                    }} >
                    <React.Suspense fallback={'Loading...'} >
                        <this.Conversation frag={data.thread as JMChat_messages$key} />
                    </React.Suspense>
                    {!threadId && <Typography variant='body1' color='text.secondary' sx={{ alignSelf: 'center', flex: 1, textAlign: 'center', py: '100px' }} >Please type a message to start a conversation.</Typography>}
                    {sendingMessage && <LoadingDots my={2} />}
                </Box>

                <TextField
                    variant='outlined'
                    value={this.state.message}
                    onChange={(e) => this.setState(prev => ({ ...prev, message: e.target.value }))}
                    placeholder='Type your message'
                    title='Send a message'
                    hiddenLabel
                    multiline
                    disabled={sendingMessage}
                    sx={[{
                        justifyContent: 'center',
                        width: "100%",
                        alignSelf: 'center',
                        transition: "all 500ms ease-in-out",
                        height: 'max-content',
                        bgcolor: 'background.default',
                        "& .MuiOutlinedInput-root": {
                            boxShadow: theme => `0px 0px 10px 0px ${theme.palette.divider}`,
                            "&.Mui-focused": {
                                boxShadow: theme => `0px 0px 10px 5px ${theme.palette.divider}`
                            },
                            borderRadius: theme => theme.spacing(2),
                            "& fieldset": {
                                borderColor: theme => theme.palette.divider,
                            },
                        }
                    }]}
                    InputProps={{
                        endAdornment: (
                            <IconButton
                                disabled={!Boolean(this.state.message)}
                                children={<Send />}
                                onClick={fire}
                                color='primary'
                                size='small'
                                sx={{ height: '1em', width: '1em' }}
                            />
                        )
                    }}
                />

            </Box>
        )
    }

    Socials = () => {
        const links = [
            { name: 'whatsapp', link: 'https://wa.me/916359337591', icon: 'https://img.icons8.com/fluency/48/000000/whatsapp.png' },
            { name: 'instagram', link: 'https://instagram.com/jainamsha', icon: 'https://img.icons8.com/fluency/48/000000/instagram-new.png' },
            { name: 'twitter', link: 'https://twitter.com/jae_py', icon: 'https://img.icons8.com/fluency/48/000000/twitter.png' },
            { name: 'linkedin', link: 'https://linkedin.com/in/jaex', icon: 'https://img.icons8.com/fluency/48/000000/linkedin.png' },
            { name: 'github', link: 'https://github.com/psyborgs-git', icon: 'https://img.icons8.com/fluency/48/000000/github.png' },
            { name: 'email', link: 'mailto:j@jaesmetavrse.com', icon: 'https://img.icons8.com/fluency/48/000000/email.png' },
        ];
        return (
            <Stack
                direction='column'
                my='auto' p={2}
                justifyContent='center'
                alignItems='center'
                flexWrap='wrap' gap={2}
                sx={{
                    maxHeight: '80vh',
                    width: 'max-content',
                    px: 2,
                    "& .MuiIconButton-root": {
                        bgcolor: 'background.default',
                        boxShadow: theme => `0px 0px 15px 0px ${theme.palette.divider}`,
                        borderRadius: '15px',
                        transition: 'all 0.5s ease-in-out',
                        width: '210px',
                        height: '100px',
                        justifyContent: 'space-between',
                        alignItems: 'flex-start',
                        flexDirection: 'column',
                        "> img": {
                            height: '36px',
                            width: '36px',
                            objectFit: 'contain'
                        },
                        "& .MuiTypography-root": {
                            fontSize: '0.75rem',
                            fontWeight: '500',
                            color: 'text.secondary',
                            width: '100%',
                            textAlign: 'center',
                        },
                    }
                }} >
                {links.map((e, i) => (
                    <IconButton LinkComponent='a' href={e.link} key={e.name} >
                        <Box component="img" src={e.icon} alt={e.name} />
                        <Typography variant='caption' color='text.secondary' >{e.name}</Typography>
                    </IconButton>
                ))}
            </Stack>
        );
    }

    ThemeSwitch = () => {
        const { darkMode, toggleDarkMode } = useContext(ThemeContext);

        return (
            <Stack
                direction='row' gap={2} px={2} sx={{
                    height: '60px',
                    my: '10px',
                    mx: 'auto',
                    bgcolor: 'background.paper',
                    borderRadius: '15px',
                    width: 'max-content',
                    justifyContent: 'center',
                    alignItems: 'center',
                    display: 'flex',
                    "& .MuiIconButton-root": {
                        boxShadow: theme => `0px 0px 10px 0px ${theme.palette.divider}`,
                        bgcolor: 'background.default',
                        borderRadius: '15px',
                        transition: 'all 0.5s ease-in-out',
                    },
                    "& .Mui-disabled": {
                        bgcolor: 'background.paper',
                        boxShadow: 'none'
                    }
                }}
            >
                <IconButton disabled={darkMode} onClick={toggleDarkMode}>
                    <Brightness4 />
                </IconButton>
                <IconButton disabled={!darkMode} onClick={toggleDarkMode}>
                    <Brightness7 />
                </IconButton>
            </Stack>
        )
    }

    render() {
        const { Chat, Socials, ThemeSwitch } = this;
        return (
            <Box sx={{ height: '100%', width: '100%', overflow: 'hidden' }} component={Stack} >
                <Container maxWidth='xl' sx={{ px: { xs: 0 } }} >
                    <ThemeSwitch />
                    <Box sx={{ overflowX: 'scroll', p: '10px', minWidth: '100%' }} >
                        <Stack direction='row' gap={2} width='max-content' >
                            <Chat />
                            <Socials />
                        </Stack>
                    </Box>
                </Container>
            </Box>
        );
    }

}

export default JM;
