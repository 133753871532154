/**
 * @generated SignedSource<<ed700d0f591e5e27749df612969cc30b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type JMMutation$variables = {
  botId?: string | null;
  text: string;
  thread?: string | null;
};
export type JMMutation$data = {
  readonly message: {
    readonly success: boolean | null;
    readonly error: string | null;
    readonly threadId: string | null;
    readonly currentMessage: {
      readonly id: string;
      readonly " $fragmentSpreads": FragmentRefs<"JMChat_message">;
    } | null;
    readonly response: ReadonlyArray<{
      readonly id: string;
      readonly " $fragmentSpreads": FragmentRefs<"JMChat_message">;
    } | null> | null;
  } | null;
};
export type JMMutation = {
  variables: JMMutation$variables;
  response: JMMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "botId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "text"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "thread"
  }
],
v1 = [
  {
    "fields": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "botId",
            "variableName": "botId"
          },
          {
            "kind": "Variable",
            "name": "text",
            "variableName": "text"
          },
          {
            "kind": "Variable",
            "name": "thread",
            "variableName": "thread"
          }
        ],
        "kind": "ObjectValue",
        "name": "create"
      }
    ],
    "kind": "ObjectValue",
    "name": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "success",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "error",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "threadId",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = [
  (v5/*: any*/),
  {
    "args": null,
    "kind": "FragmentSpread",
    "name": "JMChat_message"
  }
],
v7 = [
  (v5/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "content",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "role",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "createdAt",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "JMMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "MessageMutationPayload",
        "kind": "LinkedField",
        "name": "message",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "MessageType",
            "kind": "LinkedField",
            "name": "currentMessage",
            "plural": false,
            "selections": (v6/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "MessageType",
            "kind": "LinkedField",
            "name": "response",
            "plural": true,
            "selections": (v6/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutations",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "JMMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "MessageMutationPayload",
        "kind": "LinkedField",
        "name": "message",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "MessageType",
            "kind": "LinkedField",
            "name": "currentMessage",
            "plural": false,
            "selections": (v7/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "MessageType",
            "kind": "LinkedField",
            "name": "response",
            "plural": true,
            "selections": (v7/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "0bfb20ad08ad62d968ae0503a3810eae",
    "id": null,
    "metadata": {},
    "name": "JMMutation",
    "operationKind": "mutation",
    "text": "mutation JMMutation(\n  $botId: String\n  $text: String!\n  $thread: ID\n) {\n  message(input: {create: {text: $text, botId: $botId, thread: $thread}}) {\n    success\n    error\n    threadId\n    currentMessage {\n      id\n      ...JMChat_message\n    }\n    response {\n      id\n      ...JMChat_message\n    }\n  }\n}\n\nfragment JMChat_message on MessageType {\n  id\n  content\n  role\n  createdAt\n}\n"
  }
};
})();

(node as any).hash = "b6a1de401af614c20173abf50bd39b2a";

export default node;
