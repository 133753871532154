/**
 * @generated SignedSource<<f6abb174672cbb1a4a6a7c099da3670e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type OpenaiapiMessageRoleChoices = "USER" | "ASSISTANT" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type JMChat_message$data = {
  readonly id: string;
  readonly content: any | null;
  readonly role: OpenaiapiMessageRoleChoices;
  readonly createdAt: any;
  readonly " $fragmentType": "JMChat_message";
};
export type JMChat_message$key = {
  readonly " $data"?: JMChat_message$data;
  readonly " $fragmentSpreads": FragmentRefs<"JMChat_message">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "JMChat_message",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "content",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "role",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    }
  ],
  "type": "MessageType",
  "abstractKey": null
};

(node as any).hash = "7626d6129f94498f3ef83e4467693119";

export default node;
