import { createTheme } from '@mui/material/styles';

// Light theme colors
const lightTheme = createTheme({
    palette: {
        mode: 'light',
        primary: {
            main: '#1abc9c', // A cool turquoise
        },
        secondary: {
            main: '#2c3e50', // A dark desaturated blue
        },
        text: {
            primary: '#000', // Primary text color
            secondary: '#6c757d', // Secondary text color
        },
        background: {
            default: '#ecf0f1', // A light grayish cyan
            paper: '#fff',
        },
    },
    components: {
        MuiPaper: {
            styleOverrides: {
                root: {
                    boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.1)', // Paper shadow
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: '4px', // Button border radius
                    background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)', // Gradient background
                },
            },
        },
        MuiTypography: {
            styleOverrides: {
                root: {
                    textAlign: 'left'
                }
            }
        },
        MuiChip: {
            styleOverrides: {
                root: {
                    borderRadius: '4px', // Button border radius
                },
            },
        }
    },
    typography: {
        'fontFamily': "Roboto, sans-serif"
    }
});

const darkTheme = createTheme({
    palette: {
        mode: 'dark',
        primary: {
            main: '#3498db', // A bright blue
        },
        secondary: {
            main: '#9b59b6', // A bright purple
        },
        text: {
            primary: '#f8f9fa', // Primary text color
            secondary: '#adb5bd', // Secondary text color
        },
        background: {
            default: '#2c3e50', // A dark desaturated blue
            paper: '#34495e', // A darker desaturated blue
        },
    },
    components: {
        MuiPaper: {
            styleOverrides: {
                root: {
                    boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.2)', // Paper shadow
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: '4px', // Button border radius
                    background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)', // Gradient background
                },
            },
        },
        MuiTypography: {
            styleOverrides: {
                root: {
                    textAlign: 'left'
                }
            }
        },
        MuiChip: {
            styleOverrides: {
                root: {
                    borderRadius: '4px', // Button border radius
                },
            },
        }
    },
    typography: {
        'fontFamily': "Roboto, sans-serif"
    }
});

export { lightTheme, darkTheme };
