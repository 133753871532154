import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import RootLoader from './loader/Root';
import ThemeWrapper from './theme/Wrapper';
// import { RouterProvider } from 'react-router-dom';
// import router from './routes';
import { RelayEnvironmentProvider } from 'react-relay';
import prodEnv, { url_endPoint } from './relay/environment';
import { Helmet } from 'react-helmet';
import JM from './JM';


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <Helmet>

      {/* <meta name="twitter:card" content="summary_large_image" /> */}
    </Helmet>
    <RelayEnvironmentProvider
      environment={
        prodEnv({
          'apiToken': 'JAE_CARD_KEY',
          'token': localStorage.getItem('token') || '',
          'url': url_endPoint + 'oai/api/v1',
        })
      } >
      <ThemeWrapper>
        <React.Suspense fallback={<RootLoader />} >
          {/* <RouterProvider router={router} /> */}
          <JM />
        </React.Suspense>
      </ThemeWrapper>
    </RelayEnvironmentProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
